import Vue from 'vue';
import App from './App.vue';
import router from './router.js';

import {ButtonPlugin} from 'bootstrap-vue';
import {CardPlugin} from 'bootstrap-vue';
import {FormCheckboxPlugin} from 'bootstrap-vue';
import {FormDatepickerPlugin} from 'bootstrap-vue';
import {FormInputPlugin} from 'bootstrap-vue';
import {FormPlugin} from 'bootstrap-vue';
import {FormSelectPlugin} from 'bootstrap-vue';
import {FormTextareaPlugin} from 'bootstrap-vue';
import {IconsPlugin} from 'bootstrap-vue';
import {LayoutPlugin} from 'bootstrap-vue';
import {LinkPlugin} from 'bootstrap-vue';
import {ModalPlugin} from 'bootstrap-vue';
import {NavbarPlugin} from 'bootstrap-vue';
import {OverlayPlugin} from 'bootstrap-vue';
import {PaginationPlugin} from 'bootstrap-vue';
import {SidebarPlugin} from 'bootstrap-vue';
import {SpinnerPlugin} from 'bootstrap-vue';
import {TablePlugin} from 'bootstrap-vue';
import {TooltipPlugin} from 'bootstrap-vue';

import '@/assets/css/main.scss';

// Optionally install the BootstrapVue icon components plugin
Vue.use(ButtonPlugin);
Vue.use(CardPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(IconsPlugin);
Vue.use(LayoutPlugin);
Vue.use(LinkPlugin);
Vue.use(ModalPlugin);
Vue.use(NavbarPlugin);
Vue.use(OverlayPlugin);
Vue.use(PaginationPlugin);
Vue.use(SidebarPlugin);
Vue.use(SpinnerPlugin);
Vue.use(TablePlugin);
Vue.use(TooltipPlugin);

Vue.config.productionTip = false;

import {initializeApp, getApp} from 'firebase/app';
import {getFunctions, connectFunctionsEmulator} from 'firebase/functions';
import {getAuth, connectAuthEmulator} from 'firebase/auth';

// Debug flag
const debug = false;

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAgyIacAwdjVLTFQiPRLV1iLAMKAjdhd2A',
  authDomain: 'dezran.com',
  projectId: 'dezran-79133',
  storageBucket: 'dezran-79133.appspot.com',
  messagingSenderId: '849135399575',
  appId: '1:849135399575:web:02461bc3aa3bbda9546fd2',
  measurementId: 'G-XBHT49DEEG',
};

initializeApp(firebaseConfig);
if (debug) {
  connectFunctionsEmulator(getFunctions(getApp()), 'localhost', 5001);
  connectAuthEmulator(getAuth(), 'http://localhost:9099');
}

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
