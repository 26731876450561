import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

function loadView(view) {
  return () =>
    import(/* webpackChunkName: 'view-[request]' */ `@/views/${view}.vue`);
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: loadView('home'),
    },
    {
      path: '/login',
      name: 'login',
      component: loadView('login'),
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: loadView('dashboard'),
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: loadView('calendar'),
    },
    {
      path: '/edit_name',
      name: 'edit_name',
      component: loadView('edit_name'),
    },
    {
      path: '/edit_company',
      name: 'edit_company',
      component: loadView('edit_company'),
    },
    {
      path: '/contact',
      name: 'contact',
      component: loadView('contact'),
    },
    {
      path: '/attendance_settings',
      name: 'attendance_settings',
      component: loadView('attendance_settings'),
      children: [
        {
          path: 'editClient',
          components: {
            settingsView: loadView('edit_client'),
          },
        },
        {
          path: 'editPerson',
          components: {
            settingsView: loadView('edit_person'),
          },
        },
        {
          path: 'editSite',
          components: {
            settingsView: loadView('edit_site'),
          },
        },
        {
          path: 'editConstruction',
          components: {
            settingsView: loadView('edit_construction'),
          },
        },
        {
          path: 'editDefaultValues',
          components: {
            settingsView: loadView('edit_default_values'),
          },
        },
      ],
    },
    {
      path: '/company_settings',
      name: 'company_settings',
      component: loadView('company_settings'),
      children: [
        {
          path: 'companyInformation',
          components: {
            settingsView: loadView('edit_company'),
          },
        },
        {
          path: 'accountInformation',
          components: {
            settingsView: loadView('edit_bankaccount'),
          },
        },
        {
          path: 'inviteUser',
          name: 'invite_user',
          components: {
            settingsView: loadView('invite_attendance'),
          },
        },
        {
          path: 'manageUsers',
          name: 'manage_users',
          components: {
            settingsView: loadView('edit_members'),
          },
        },
      ],
    },
    {
      path: '/edit_expense',
      name: 'edit_expense',
      component: loadView('edit_expense'),
    },
    {
      path: '/attendance_report',
      name: 'attendance_report',
      component: loadView('attendance_report'),
    },
    {
      path: '/invoice_report',
      name: 'invoice_report',
      component: loadView('invoice_report'),
    },
    {
      path: '/join_report',
      name: 'join_report',
      component: loadView('join_attendance'),
    },
    {
      path: '/edit_invoice',
      name: 'edit_invoice',
      component: loadView('edit_invoice'),
    },
    {
      path: '/invoice_list',
      name: 'invoice_list',
      component: loadView('invoice_list'),
    },
    {
      path: '/print_invoice',
      name: 'print_invoice',
      component: loadView('print_invoice'),
    },
    {
      path: '/help',
      name: 'help',
      component: loadView('help'),
    },
    {
      path: '/checkoutSuccess',
      name: 'checkoutSuccess',
      component: loadView('subscription_complete'),
    },
    {
      path: '/termsOfUse',
      name: 'termsOfUse',
      component: loadView('terms_of_use'),
    },
    {
      path: '/privacyPolicy',
      name: 'privacyPolicy',
      component: loadView('privacy_policy'),
    },
    {
      path: '/hyouji',
      name: 'hyouji',
      component: loadView('tokutei_hyouji'),
    },
    {
      path: '/*',
      name: '404',
      component: loadView('404'),
    },
  ],
});
