<template>
  <div id='app' class='m-0 p-0'>
    <b-navbar type='dark' variant='primary' toggleable='md'>
      <b-navbar-brand to='/'>
        <img src='@/assets/images/logo.svg' width='96px' />
      </b-navbar-brand>
      <b-navbar-toggle target='nav-collapse' />
      <b-collapse id='nav-collapse' is-nav>
        <b-navbar-nav>
          <b-nav-item to='/dashboard' v-if='authenticated'>ダッシュボード</b-nav-item>
          <b-nav-item to='/calendar' v-if='authenticated'>カレンダー</b-nav-item>
          <b-nav-item v-if='role === "admin" || role === "owner"' to='/edit_expense'>経費管理</b-nav-item>
          <b-nav-item-dropdown text='帳票' v-if='role === "owner"'>
            <b-dropdown-item to='/attendance_report'>出勤簿出力</b-dropdown-item>
            <b-dropdown-item to='/invoice_report'>請求書出力</b-dropdown-item>
            <b-dropdown-item to='/edit_invoice'>請求書作成</b-dropdown-item>
            <b-dropdown-item to='/invoice_list'>請求書一覧</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if='authenticated' text='設定' right>
            <b-dropdown-item to='/join_report'>出勤簿へ参加</b-dropdown-item>
            <b-dropdown-item to='/edit_name'>表示氏名の設定</b-dropdown-item>
            <b-dropdown-divider v-if='subscriptionStatus === "active"' />
            <!-- User bought subscription and created company, s/he is owner -->
            <b-dropdown-item v-if='subscriptionStatus === "active" && role === "owner"'
              to='/attendance_settings/editClient'>出勤簿設定</b-dropdown-item>
            <b-dropdown-item v-if='subscriptionStatus === "active" && role === "owner"'
              to='/company_settings/companyInformation'>会社設定</b-dropdown-item>
            <!-- User bought subscription but haven't register a company -->
            <b-dropdown-item v-if='subscriptionStatus === "active" && role === "guest"' to='/edit_company'>会社の登録
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item v-if='subscriptionStatus === "none"' @click='createCheckoutSession'>有料プランの購入
            </b-dropdown-item>
            <b-dropdown-item v-if='subscriptionStatus === "active" || subscriptionStatus === "paid"'
              @click='getCustomerPortal'>有料プランの変更</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item v-b-toggle.sidebar-help>ヘルプ</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class='ml-auto'>
          <b-nav-form>
            <b-button v-b-tooltip.hover='{ variant: "primary" }' :title='username' v-if='authenticated'
              id='signOutButton' variant='light' v-on:click='signOut'>
              <img :src='photoUrl' v-if='authenticated && photoUrl !== null' class='profileImage' />
              <b-icon v-else icon='person-fill' />
              ログアウト
            </b-button>
            <b-button v-else variant='light' to='/login'>
              <b-icon icon='person-fill' />
              ログイン
            </b-button>
          </b-nav-form>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div v-if='authenticated'>
      <b-container fluid>
        <b-row class='p-1 bg-primary' v-if='this.companies.length >= 1'>
          <b-col class='text-white' cols='3'>会社名</b-col>
          <b-col>
            <b-form-select v-model='current_company' :options='companies' :change='companyChanged' />
          </b-col>
        </b-row>
        <b-row class='bg-primary' v-else>
          <b-col class='p-2 text-white'>現在参加している出勤簿はありません。</b-col>
        </b-row>
      </b-container>
    </div>
    <div id='content'>
      <b-overlay :show='authenticated && (newUser || !agreedToTOU)' opacity='0.85' no-wrap>
        <template #overlay>
          <div v-if='!agreedToTOU' role='dialog' aria-modal='true' tabindex='-1' aria-labelledby='wizard-title'>
            <div>
              <p><strong id='wizard-title'>利用規約への同意</strong></p>
              <p>DEZRAN（デズラン）をご利用頂くにあたり、ユーザーには本サービスの利用規約に同意して頂く必要があります。<br />
                利用規約をご理解いただいた上で、同意頂ける場合、［利用規約に同意する］ボタンをタップしてください。同意していただけない場合、本サービスへのログインは引き続きできますが、本サービスのほぼ全ての機能をご利用いただけません。
              </p>
              <b-container>
                <b-row>
                  <b-col>
                    <b-button active-class='primary' class='mx-2' @click='notAgreeToTOU'>利用規約に同意しない</b-button>
                    <b-button @click='agreeToTOU'>利用規約に同意する</b-button>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>
          <div v-if='wizardStep === 1' role='dialog' aria-modal='true' tabindex='-1' aria-labelledby='wizard-title'>
            <p><strong id='wizard-title'>DEZRANへようこそ！ 初期設定 1/2</strong></p>
            <p>
              DEZRANを初めて使うために、簡単な初期設定を行います。<br />
              初めにあなたがDEZRANで使用する氏名を設定してください。表示されている名前はあなたがログインに使用したアカウントから取得していますが、DEZRANで使用する氏名を別に設定することができます。
            </p>
            <ValidationObserver ref='observer' v-slot='{ invalid }' immediate>
              <b-container>
                <b-row class='my-3'>
                  <b-col md='3'>あなたの氏名</b-col>
                  <b-col>
                    <ValidationProvider v-slot='{ errors, valid }' name='氏名' rules='required'>
                      <b-form-input type='text' v-model='newUsername' placeholder='氏名を入力してください。' :state='valid' />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row class='my-3' align-h='end'>
                  <b-col>
                    <b-button @click='nextWizard' :disabled='invalid'>次へ</b-button>
                  </b-col>
                </b-row>
              </b-container>
            </ValidationObserver>
          </div>
          <div v-else-if='wizardStep === 2' role='dialog' aria-modal='true' tabindex='-1'
            aria-labelledby='wizard-title'>
            <p><strong id='wizard-title'>DEZRANへようこそ！ 初期設定 2/2</strong></p>
            <p>
              出勤記録を入力するには、ユーザーは会社に所属する必要があります。会社に属するには以下の２つの方法があります。
            <ol>
              <li>すでにDEZRANを使用している会社より出勤簿参加コードを受け取り、その会社の出勤簿に参加する。</li>
              <li>自分の会社をDEZRANに登録する。（有料サービス）</li>
            </ol>
            出勤簿参加コードを受け取っている場合は、以下にコードを入力し［参加］ボタンをタップ（クリック）してください。<br />
            参加コードを受け取ってない場合は［完了］ボタンをタップしてください。
            </p>
            <b-container>
              <b-row class='my-3'>
                <b-col md='4'>出勤簿参加コード</b-col>
                <b-col>
                  <b-form-input type='text' v-model='inviteCode' />
                </b-col>
              </b-row>
              <b-row class='my-3' align-h='end'>
                <b-col>
                  <b-button @click='prevWizard'>戻る</b-button>
                  <b-button class='mx-3' @click='nextWizard'>完了</b-button>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </template>
      </b-overlay>
      <transition mode='out-in'>
        <router-view />
      </transition>
    </div>
    <b-sidebar id='sidebar-help' title='ヘルプ' header-class='bg-primary text-white' :no-close-on-route-change='true' right
      shadow backdrop>
      <iframe src="/help/help.html" frameborder="0" width='100%' height='100%'></iframe>
    </b-sidebar>
    <footer class='bg-primary text-light px-1'>
      <b-container>
        <b-row align-v='end'>
          <b-col>
            <ul class='footer'>
              <li>
                <b-link to='termsOfUse' class='text-white'><small>利用規約</small></b-link>
              </li>
              <li>
                <b-link to='privacyPolicy' class='text-white'><small>プライバシーポリシー</small></b-link>
              </li>
              <li>
                <b-link to='hyouji' class='text-white'><small>特定商取引法に基づく表記</small></b-link>
              </li>
              <li>
                <b-link to='contact' class='text-white'><small>お問い合わせ</small></b-link>
              </li>
            </ul>
          </b-col>
          <b-col md='2' class='text-right'><small>DEZRAN</small></b-col>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>
<script>
import { getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { required } from 'vee-validate/dist/rules';
import { localize, extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import ja from 'vee-validate/dist/locale/ja.json';

extend('required', required);
localize('ja', ja);

export default {
  name: 'App',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data: function () {
    return {
      authenticated: false,
      photoUrl: '',
      companies: [],
      current_company: null,
      newUser: false,
      newUsername: '',
      agreedToTOU: true,
      inviteCode: '',
      wizardStep: 0,
      role: 'guest',
      subscriptionStatus: 'none',
      priceId: '',
    }
  },
  computed: {
    username: () => {
      return sessionStorage.username;
    },
  },
  methods: {
    signOut: function () {
      getAuth(getApp()).signOut()
        .then(() => {
          // User signed out
          sessionStorage.clear();
          this.authenticated = false;
          this.companies = [];
          this.current_company = null;
          this.photoURL = '';
          this.role = 'guest';
          this.subscriptionStatus = 'none';

          if (this.$route.name !== 'dashboard') {
            this.$router.push('/home');
          }
        });
    },
    companyChanged: function () {
      httpsCallable(getFunctions(getApp()), 'user-setDefaultCompany')({
        companyId: this.current_company,
      });
    },
    nextWizard: function () {
      if (this.wizardStep === 1) {
        this.updateName();
      }

      this.wizardStep++;

      if (this.wizardStep === 3) {
        this.newUser = false;
      }
    },
    prevWizard: function () {
      this.wizardStep--;
    },
    updateName: function () {
      if (getAuth(getApp()).currentUser.displayName !== this.newUsername) {
        httpsCallable(getFunctions(getApp()), 'user-updateUsername')({
          name: this.newUsername,
        }).then((response) => {
          if (response.data.requestStatus === 'success') {
            sessionStorage.username = this.newUsername;
          }
        }).catch((err) => {
          console.error(err.message, err.stack);
        });
      }
    },
    createCheckoutSession: function () {
      httpsCallable(getFunctions(getApp()), 'createCheckoutSession')({
      }).then(response => {
        console.log(JSON.stringify(response));
        if (response.data.requestStatus === 'success') {
          window.location.href = response.data.checkoutUrl;
        }
      }).catch(error => {
        console.error(error.message);
      });
    },
    getCustomerPortal: function () {
      httpsCallable(getFunctions(getApp()), 'getCustomerPortalUrl')({
        returnUrl: this.$router.currentRoute.path,
      }).then((response) => {
        if (response.data.requestStatus === 'success') {
          window.location.href = response.data.portalUrl;
        }
      }).catch((error) => {
        console.error(error.message);
      });
    },
    agreeToTOU: function () {
      httpsCallable(getFunctions(getApp()), 'user-agreeToTermsOfUse')()
        .then((response) => {
          if (response.data.requestStatus === 'success') {
            this.agreedToTOU = true;
            if (this.newUser) {
              this.wizardStep = 1;
            }
          }
        }).catch((error) => {
          console.error(error.message);
        });
    },
    notAgreeToTOU: function () {
      const message = '本サービスへの利用規約に同意していただけない場合、本サービスを引き続き利用して頂くことはできません。<br/>' +
        '利用規約を再度確認して頂く場合は［キャンセル］ボタンをタップしてください。<br/>' +
        '利用規約に同意せずに本サービスからログアウトする場合は［同意せずにログアウトする］ボタンをタップしてください。<br/>' +
        '<p>再度ログインすると利用規約画面が表示されます。<br/>' +
        '再検討したのち、本サービスを御利用する場合は再度ログインし利用規約に同意を行ってください。<br/>' +
        '本サービスのご利用をお待ちしております。</p>';
      const messageNode = this.$createElement('div', { domProps: { innerHTML: message } });
      this.$bvModal.msgBoxConfirm(messageNode, {
        title: '利用規約に同意していただけないユーザー様へ',
        size: 'lg',
        buttonSize: 'lg',
        okTitle: '同意せずにログアウトする',
        cancelTitle: 'キャンセル',
      }).then((result) => {
        if (result) {
          this.signOut();
        }
      }).catch((error) => {
        console.log(error.message);
      });
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      // Set firebase authentication observer
      const app = getApp();
      const functions = getFunctions(app);
      getAuth().onAuthStateChanged((user) => {
        if (user) {
          // User signed in
          this.authenticated = true;
          this.photoUrl = user.photoURL;
          httpsCallable(functions, 'user-sessionLogin')({
            token: sessionStorage.idToken,
          }).then((response) => {
            if (response.data.requestStatus === 'success') {
              this.newUser = response.data.newUser;
              this.newUsername = user.displayName;
              this.agreedToTOU = response.data.agreedToTOU;
              this.subscriptionStatus = response.data.subscriptionStatus;
              // Write to session storage
              sessionStorage.username = user.displayName;
              sessionStorage.userId = response.data.userId;
              sessionStorage.subscriptionStatus = this.subscriptionStatus;

              if (!this.agreedToTOU) {
                this.wizardStep = 0;
              } else if (this.newUser) {
                this.wizardStep = 1;
              }

              response.data.companies.forEach((c) => {
                this.companies.push({ value: c._id, text: c.name });
              });
              if (response.data.defaultCompany !== null) {
                this.current_company = response.data.defaultCompany;
              }
              this.role = response.data.role;
              sessionStorage.role = this.role;
              router.push('dashborad');
            }
          }).catch((err) => {
            console.error(err.message, err.stack);
          });
        }
      });
    });
  },
};
</script>
